<template>
  <div class="taskDetail">
    <div class="link">
      <img v-show="!link" @click="toPath('/linkRank',{id: taskid,title: taskdetail.title,desc: taskdetail.describe,imgUrl: adddom(taskdetail.thumb)})" src="../../public/statics/images/task/detail_link.png"/>
      <img v-show="link" @click="toFollow" style="width:1.5rem;" src="../../public/statics/images/task/follow.png"/>
    </div>
    <van-nav-bar
      title
      left-text
      left-arrow
      :fixed="false"
      :border="false"
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
    />
    <!-- 红包暂时不开 -->
    <!-- <div class="card">
      <div>
        <span>转发朋友圈</span>
        <p>
          <img src="../../public/statics/images/task/rule.png" /> 规则
        </p>
      </div>
      <div>
        ¥
        <span>10</span>
      </div>
      <div>截止时间：{{taskdetail.created_at}}</div>
    </div> -->
    <div class="htmlTemp">
      <h1>{{taskdetail.title}}</h1>
      <div class="flexBox">
        <div class="boxLeft">
          <img
            v-if="false"
            :src="adddom(taskdetail.speaker ? taskdetail.speaker.thumb : '')"
          />
          <div>
            <h2 v-if="false">{{taskdetail.speaker.name}}</h2>
            <!-- <p style="margin-top:0;">提交时间：{{timestampToTime(taskdetail.created_at, 1)}}</p> -->
          </div>
          <h2>我的运城　<span>{{timestampTotime(taskdetail.created_at)}}</span></h2>
        </div>
        <div class="boxRight" >浏览量:{{taskdetail.hit}}人次</div>
      </div>
      <van-divider />
      <!-- <img style="display:block;margin:0 auto;" :src="adddom(taskdetail.thumb)" /> -->
      <div id="imgDetail" v-html="taskdetail.content"></div>
      <!-- <img src="../../public/statics/images/task/detail_1.jpg" />
      <img src="../../public/statics/images/task/detail_1.jpg" />
      <p>
        “人生如戏，花月婵娟书真意；戏若人生，畅快豪情又何妨。”1月5日，随着爆款游戏《原神》2.4版“飞彩镌流年”上线，新角色云堇正式登场。盔头、靠旗、唱白……云堇身上蕴含着传统戏曲元素，成为全球玩家探究的焦点。连日来，无数网友涌进CGTN(中国国际电视台)两年前发布的京剧视频学习，短短几天里视频播放量暴增10多万。玩家留言：“我们来到这里，是因为云堇的歌声，也是出于对中国古老艺术形式的尊重。”
        中国元素成为“游戏出海”重要“基因”中国游戏企业大踏步走向海外，精品游戏登上多个国家和地区畅销榜，中国元素成为“游戏出海”的重要“基因”。
      </p>-->
      <div class="swiperTitle">推荐阅读</div>
      <!-- <van-swipe :autoplay="3000" :loop="true" :show-indicators="false" lazy-render>
        <van-swipe-item v-for="(item,index) in taskdetail.ads" :key="item.id">
          <div class="swiperItem" @click="toLink(item)">
            <img class="pic" :src="adddom(item.thumb)" />
            <div class="desc">
              <h1>{{item.title}}</h1>
              <div class="time">{{item.created_at}}</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe> -->
      <div class="swiperItem" @click="toLink(item)" v-for="(item,index) in taskdetail.ads" :key="item.id">
        <img class="pic" :src="adddom(item.thumb)" />
        <div class="desc">
          <h1>{{item.title}}</h1>
          <div class="time">{{item.created_at}}</div>
        </div>
      </div>
    </div>
    <div style="height:3rem;"></div>
    <index-footer></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import { ImagePreview } from 'vant';
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import utils from '@/api/Utils'
export default {
  name: "taskDetail",
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      link: '', // url没有link值，显示链路排行，不显示关注，反之显示关注不显示链路排行
      taskdetail: {},
      taskid: null,
      loadShow: false,
      shareUrl: require("../../public/statics/images/share.jpg"),
      getCodeUrl:"http://speaker.shanmuzhi.com/api/wxcode?url=taskDetail",
      memberUrl: "http://speaker.shanmuzhi.com/#/",
    };
  },
  components: {
    indexFooter
  },
  watch:{
      $route(to, from) {
        let that = this
        that.taskid = that.$route.query.id;
        that.getDetail();
      }
  },
  created() {
    this.getCodeUrl = this.$publicData.domain + 'api/wxcode?url=taskDetail?id='+ this.$route.query.id +'&link='+this.$route.query.link
    this.memberUrl = this.$publicData.domain + '#/wxcode?url=taskDetail?id='+ this.$route.query.id +'&link='+this.$route.query.link
    let that = this
    if(this.$route.query.id !=undefined){
      Cookies.set('taskid', this.$route.query.id)
      Cookies.set('link', this.$route.query.link)
    }
    var token = Cookies.get('spToken');
		console.log(token)
		that.loadShow = true
		that.taskid = that.$route.query.id;
		that.link = this.$route.query.link
		that.getDetail();
  },
  beforeUnmount(){
    Cookies.set('link', '')
  },
  mounted() {
    let that = this
    $('#imgDetail').unbind().on("click",'img',function(){
			// console.log($(this).parent().attr('href'))
			if ($(this).parent().attr('href')) return
      // let imgarr = $('#imgDetail').find('img')
      // for(var i=0;i<imgarr.length;i++){
      //   arr.push(imgarr[i].src)
      // }
      // let index = $(this).index()
      let arr = []
      arr.push(this.src)
      that.showImg(arr,0)
    })
    $('.link').unbind().on("touchstart", function(e) {
      $('body').css({'overflow':'hidden'})
        var e = e || window.event;
        console.log(e);
        var positionDiv = $(this).offset()
        var scrollTop = window.pageYOffset //用于FF
          || document.documentElement.scrollTop
          || document.body.scrollTop
          || 0;
        console.log(scrollTop)
        var distenceY =
          e.originalEvent.targetTouches[0].pageY - positionDiv.top + scrollTop
        $(document).on("touchmove", function(e) {
          var e = e || window.event;
          var y = e.originalEvent.targetTouches[0].pageY - distenceY
          let height = 200
          if (y < height) {
            y = height;
          } else if (y > $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.link').outerHeight(true) - 15) {
            y = $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.link').outerHeight(true) - 15
          }

          $(".link").css({
            top: y + "px"
          })
        });
        $(".link").on("touchend", function(e) {
          $('body').css({'overflow':'auto'})
          $(document).unbind("touchmove");
        });
      });
  },
  methods: {
		wxLogin(){
			var codeArr = this.getUrlCode();
			// 获取code
			if (codeArr["code"] != undefined) {
			    var code = codeArr["code"];
			    var params = {
			        code: code
			    };
			    // 绑定
			    this.$api.index.wxlogin(params).then(res => {
			        if (res.code == 200) {
			            Cookies.set('spToken', res.result.token, { expires: 365 })
			            localStorage.setItem('priv', JSON.stringify(res.result.priv))
			            if (res.result.location_status == 0) {
			              that.getLocation();
			            }
			            // that.loadShow = true
			            // that.taskid = that.$route.query.id;
			            // that.getDetail();
			            // that.link = Cookies.get("link")
									window.history.replaceState( null, null, that.memberUrl );
									setTimeout(() => {
										window.location.reload()
									}, 100);
			        }
			    });
			}else{
			  window.location.href = this.getCodeUrl;
			}
		},
    timestampTotime(timestamp){
      if (timestamp === null ||timestamp === undefined || timestamp === '') {
        return ''
      }
      timestamp = timestamp.replace(/-/g, '/')
      // 时间戳转时间
      let date = new Date(timestamp)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      let s = date.getSeconds()
      return Y + M + D + h + m
    },
    toFollow(){
      let that = this
      let arr = []
      arr.push(this.$publicData.followImg)
      that.showImg(arr,0)
    },
    showImg(arr,num){
      ImagePreview({
        images: arr,
        showIndex:false,
        startPosition: num,
        maxZoom: 6
      })
    },
    timestampToTime (timestamp, type) {
      if (!timestamp) return
      return utils.timestampToTime(timestamp, type)
    },
    // 截取url中的code方法
    getUrlCode() {
        var allUrl = location.href;
        var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
        this.winUrl = url;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest;
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getDetail() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        id: that.taskid || Cookies.get("taskid")
      };
      that.$api.index.dutyDetail(params).then(function(res) {
        that.loadShow = false;
        if (res.code == 200) {
          that.taskdetail = res.result;
          window.document.title = that.taskdetail.title
          // 分享
          let routeData = that.$router.resolve({
            path: '/taskShare',
            query: {url: that.taskdetail.url}
          })
          let tempurl = location.href;
          let shareUrl = that.taskdetail.url ? location.href.split("#")[0] + routeData.href : (that.$route.query.link ? location.href :location.href + "&link=1")
          console.log(shareUrl)
          that.$api.index.getShare({url:tempurl}).then(function(res){
            if (res.code == 200) {
              let shareConfig = res.result;
              console.log(shareConfig);
              wx.config({
                debug: shareConfig.debug,
                beta: shareConfig.beta,
                jsApiList: shareConfig.jsApiList,
                appId: shareConfig.appId,
                nonceStr: shareConfig.nonceStr, // 随机串
                timestamp: parseInt(shareConfig.timestamp), // 时间戳
                signature: shareConfig.signature // 签名
              });
              wx.ready(function () {
                var shareDataA = {
                  title: that.taskdetail.title, // 分享标题
                  desc: that.taskdetail.describe, // 分享描述
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.taskdetail.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                var shareDataB = {
                  title: that.taskdetail.title, // 分享标题
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.taskdetail.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                // setTimeout(function(){
                  wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                  wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                // },100)
              })
            }
          })
        }
      });
    },
    toLink(item){
      console.log(item)
      if (this.link){
        this.toPath('/taskDetail',{
          id: item.id,
          link: 1
        })
      } else {
        this.toPath('/taskDetail',{
          id: item.id
        })
      }
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        setTimeout(function(){
          window.location.reload();
        },100)
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    getLocation() {
      var that = this;
      var tempurl = location.href;
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          wx.ready(function() {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed; // 速度，以米/每秒计
                // var accuracy = res.accuracy; // 位置精度
                // console.log(res)
                console.log('获取成功')
                let locationData = {
                  token: Cookies.get("spToken"),
                  lat: latitude,
                  lng: longitude
                }
                that.$api.index.speakerEditArea(locationData).then(res => {
                  if (res.code == 200) {
                    // 更改
                  }
                })
              }
            })
          })
        }
      })
    }
  }
};
</script>
<style scoped>
.taskDetail .flexBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.2rem;
}
.taskDetail .flexBox .boxLeft {
  display: inline-flex;
  align-items: center;
}
.taskDetail .flexBox .boxLeft img {
  width: 0.93rem;
  height: 0.93rem;
  border-radius: 50%;
  margin-right: 0.2rem;
}
.taskDetail .flexBox .boxLeft h2 {
  font-size: 0.35rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
}
.taskDetail .flexBox .boxLeft p {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.taskDetail .flexBox .boxRight {
  line-height: 0.54rem;
  background: #f6f6f6;
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 0.1rem;
}
.taskDetail .swiperItem{
  box-sizing: border-box;
  display: flex;
  height: 1.86rem;
  overflow: hidden;
  padding-left: 0.2rem;
  margin-bottom: 0.3rem;
}
.taskDetail .swiperItem .pic{
  width:2.88rem;
  height: 1.86rem;
  margin-right: 0.3rem;
  object-fit: cover;
}
.taskDetail .swiperItem .desc{
  flex:1;
  position: relative;
}
.taskDetail .swiperItem .desc h1{
  font-size: 0.37rem;
    line-height: 0.55rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.taskDetail .swiperItem .desc .time{
  position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}
.taskDetail .swiperTitle{
  margin-top:0.4rem;
  margin-bottom: 0.15rem;
  font-size: 0.4rem;
  color: #333333;
  font-weight: bold;
  line-height: 1.46;
}
</style>
<style>
.taskDetail .van-nav-bar .van-icon {
  color: #000;
}
.taskDetail .link{
  height: 3.7rem;
  height: auto;
  background-image: none;
  text-align: right;
}
</style>
